import React, { FC, useEffect, useRef } from 'react';

import style from './customStyle.module.css';
import { MessageAvatar } from './MessageAvatar';
import { MessageHeader } from './MessageHeader';
import { ReplyForm, ReplyFormProps } from './ReplyForm';
import { ChatMessage } from './types';

export interface RepliesProps {
  replies?: ChatMessage[];
  showReplyForm?: boolean;
  originalMessageContent?: string;
  onReplyFormSubmit?: ReplyFormProps['onSubmit'];
  onReplyFormCloseClick?: ReplyFormProps['onCloseClick'];
  isElection?: boolean;
}

export const Replies: FC<RepliesProps> = ({
  replies,
  showReplyForm,
  originalMessageContent,
  onReplyFormSubmit,
  onReplyFormCloseClick,
  isElection,
}) => {
  const repliesContainerRef = useRef<HTMLDivElement>(null);
  const hasReplies = replies?.length > 0;

  // Note: Once replies appear, we scroll to them for better UX
  useEffect(() => {
    repliesContainerRef.current?.scrollIntoView({ block: 'nearest' });
  }, []);

  return (
    <div ref={repliesContainerRef}>
      {hasReplies && (
        <ol className={style.replyList}>
          {replies.map((reply) => {
            return (
              <li
                key={reply.optimisticId ?? reply.id}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingTop: 12,
                  paddingBottom: 12,
                }}
              >
                <div className={style.liveChatMessageMessage} css={{ border: 'none' }}>
                  <div className={style.message}>
                    <MessageAvatar imageUrl={reply.user_image} />

                    <div css={{ alignSelf: 'center', flexGrow: 1, maxWidth: 365, flexBasis: '50%' }}>
                      <MessageHeader isElection={isElection} username={reply.username} />
                      <div className={`${isElection ? style.messageTextElection : style.messageText}`}>
                        {reply.content}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ol>
      )}

      {showReplyForm && (
        <div css={{ marginTop: 12, paddingLeft: 24 }}>
          <ReplyForm
            autoFocus={!hasReplies}
            isElection={isElection}
            onCloseClick={onReplyFormCloseClick}
            onSubmit={onReplyFormSubmit}
            originalMessageContent={originalMessageContent}
            showCloseButton={!hasReplies}
            showOriginalMessage={hasReplies}
          />
        </div>
      )}
    </div>
  );
};
