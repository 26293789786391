export const fakeMessages = [
  {
    optimisticId: 1,
    id: 101,
    replay_id: null,
    room_id: 'general',
    content: 'Hello everyone!',
    timestamp: 1633035600000,
    username: 'Alice',
    user_image: 'https://daily-wire-production.imgix.net/badges/ckf7xa1u504ww0710pvx16zoz-1641913207244.png',
    replies: null,
  },
  {
    optimisticId: 2,
    id: 102,
    replay_id: null,
    room_id: 'general',
    content: "How's everyone doing?",
    timestamp: 1633035660000,
    username: 'Bob',
    user_image: 'https://daily-wire-production.imgix.net/badges/ckfwxlbg518c107382bzibzh9-1601926356547.png',
    replies: null,
  },
  {
    optimisticId: 3,
    id: 103,
    replay_id: null,
    room_id: 'general',
    content: 'Just got back from vacation!',
    timestamp: 1633035720000,
    username: 'Charlie',
    user_image: 'https://daily-wire-production.imgix.net/badges/ckiugqwaw0exn0766a0qpsoe4-1608307478044.png',
    replies: null,
  },
  {
    optimisticId: 4,
    id: 104,
    replay_id: null,
    room_id: 'general',
    content: "What's new around here? Has anyone seen the latest movie?",
    timestamp: 1633035780000,
    username: 'Dave',
    user_image: 'https://daily-wire-production.imgix.net/badges/ckte3tpp31ctd0854wdybjebt-1643063709461.png',
    replies: null,
  },
  {
    optimisticId: 5,
    id: 105,
    replay_id: null,
    room_id: 'general',
    content: 'Has anyone seen the latest movie?',
    timestamp: 1633035840000,
    username: 'Eve',
    user_image: 'https://daily-wire-production.imgix.net/badges/ckf7xa1u504ww0710pvx16zoz-1641913207244.png',
    replies: null,
  },
  {
    optimisticId: 6,
    id: 106,
    replay_id: null,
    room_id: 'general',
    content: 'Good evening everyone! Has anyone seen the latest movie?',
    timestamp: 1633035900000,
    username: 'Frank',
    user_image: 'https://daily-wire-production.imgix.net/badges/ckf7xa1u504ww0710pvx16zoz-1641913207244.png',
    replies: null,
  },
  {
    optimisticId: 1,
    id: 101,
    replay_id: null,
    room_id: 'general',
    content: 'Hello everyone! Good evening everyone!',
    timestamp: 1633035600000,
    username: 'Alice',
    user_image: 'https://daily-wire-production.imgix.net/badges/ckf7xa1u504ww0710pvx16zoz-1641913207244.png',
    replies: null,
  },
  {
    optimisticId: 2,
    id: 102,
    replay_id: null,
    room_id: 'general',
    content: "How's everyone doing?",
    timestamp: 1633035660000,
    username: 'Bob',
    user_image: 'https://daily-wire-production.imgix.net/badges/ckf7xa1u504ww0710pvx16zoz-1641913207244.png',
    replies: null,
  },
  {
    optimisticId: 3,
    id: 103,
    replay_id: null,
    room_id: 'general',
    content: 'Just got back from vacation! Has anyone seen the latest movie?',
    timestamp: 1633035720000,
    username: 'Charlie',
    user_image: 'https://daily-wire-production.imgix.net/badges/ckf7xa1u504ww0710pvx16zoz-1641913207244.png',
    replies: null,
  },
  {
    optimisticId: 4,
    id: 104,
    replay_id: null,
    room_id: 'general',
    content: "What's new around here? Has anyone seen the latest movie?",
    timestamp: 1633035780000,
    username: 'Dave',
    user_image: 'https://daily-wire-production.imgix.net/badges/ckf7xa1u504ww0710pvx16zoz-1641913207244.png',
    replies: null,
  },
  {
    optimisticId: 5,
    id: 105,
    replay_id: null,
    room_id: 'general',
    content: 'Has anyone seen the latest movie?',
    timestamp: 1633035840000,
    username: 'Eve',
    user_image: 'https://daily-wire-production.imgix.net/badges/ckf7xa1u504ww0710pvx16zoz-1641913207244.png',
    replies: null,
  },
  {
    optimisticId: 6,
    id: 106,
    replay_id: null,
    room_id: 'general',
    content: 'Good morning everyone! Has anyone seen the latest movie?',
    timestamp: 1633035900000,
    username: 'Frank',
    user_image: 'https://daily-wire-production.imgix.net/badges/ckf7xa1u504ww0710pvx16zoz-1641913207244.png',
    replies: null,
  },
];
