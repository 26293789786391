import React, { memo } from 'react';

import style from './customStyle.module.css';
import { MessageAvatar } from './MessageAvatar';
import { Replies } from './Replies';
import { ReplyToggle } from './ReplyToggle';
import { ChatMessage } from './types';

export interface MessageProps {
  message: ChatMessage;
  showReplies?: boolean;
  newRepliesAllowed?: boolean;
  onRepliesToggle?: (messageId: number, active?: boolean) => void;
  onReplyFormSubmit?: (originalMessageId: number, replyMessage?: string) => void;
  isElection?: boolean;
}

export const Message = memo<MessageProps>(
  ({ message, showReplies, newRepliesAllowed, onRepliesToggle, onReplyFormSubmit, isElection }) => {
    const handleReplyFormSubmit = (replyMessage?: string) => {
      onReplyFormSubmit?.(message.id, replyMessage);
    };

    return (
      <div className={`${isElection ? style.messageRootElection : style.messageRoot}`}>
        <div className={style.liveChatMessageMessage}>
          <div className={style.message}>
            <MessageAvatar imageUrl={message.user_image} />

            <div
              css={{
                alignSelf: 'center',
                flexGrow: 1,
                maxWidth: 365,
                flexBasis: '50%',
                marginBottom: 12,
              }}
            >
              <div className={`${isElection ? style.messageTextElection : style.messageText}`}>
                <span>{message.username}</span>
                {message.content}
              </div>
            </div>
          </div>
        </div>

        {(newRepliesAllowed || message.replies?.length > 0) && (
          <ReplyToggle
            active={showReplies}
            onToggle={(active) => onRepliesToggle?.(message.id, active)}
            replyCount={message.replies?.length}
          />
        )}

        {showReplies && (
          <div css={{ marginBottom: 12 }}>
            <Replies
              isElection={isElection}
              onReplyFormCloseClick={() => onRepliesToggle?.(message.id, false)}
              onReplyFormSubmit={handleReplyFormSubmit}
              originalMessageContent={message.content}
              replies={message.replies}
              showReplyForm={newRepliesAllowed}
            />
          </div>
        )}
      </div>
    );
  },
);

Message.displayName = 'Message';
