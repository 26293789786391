import React, { FC } from 'react';

import { Icon } from '@/components/design-system';

import { ThemeType } from '../../theme';

import style from './customStyle.module.css';
import { isUserAdmin } from './utils';

export interface MessageHeaderProps {
  username?: string;
  isElection?: boolean;
}

export const MessageHeader: FC<MessageHeaderProps> = ({ username, isElection }) => {
  const isAuthorAdmin = isUserAdmin(username);

  return (
    <div className={`${isElection ? style.staffNameElection : style.staffName}`}>
      {username ?? 'Unknown'}
      {isAuthorAdmin && (
        <Icon css={(theme: ThemeType) => ({ color: theme.colors.red, marginLeft: '.25rem' })} name='BADGE' size={21} />
      )}
    </div>
  );
};
