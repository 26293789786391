/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import React, { ChangeEvent, FC, useEffect, useState } from 'react';

import { LIVE_CHAT_MAX_CHAT_MESSAGE_LENGTH } from '@/lib/constants';

import style from './customStyle.module.css';

export interface MessageFormProps {
  onSubmit?: (message?: string) => void;
  autoFocus?: boolean;
  throttleIntervalMs?: number;
  isElection?: boolean;
}

export const MessageForm: FC<MessageFormProps> = ({ onSubmit, autoFocus, throttleIntervalMs = 1000, isElection }) => {
  const [message, setMessage] = useState('');
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  const handleTextareaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length <= LIVE_CHAT_MAX_CHAT_MESSAGE_LENGTH) {
      setMessage(e.target.value);
    }
  };

  const submit = () => {
    onSubmit?.(message?.trim());
    setMessage('');
    setSubmitButtonDisabled(true);
  };

  useEffect(() => {
    if (!submitButtonDisabled) return;

    const timeoutId = setTimeout(() => setSubmitButtonDisabled(false), throttleIntervalMs);
    return () => clearTimeout(timeoutId);
  }, [submitButtonDisabled, throttleIntervalMs]);

  return (
    <div className={`${isElection ? style.messageFormRootElection : style.messageFormRoot}`}>
      <textarea
        aria-label='New message'
        autoFocus={autoFocus}
        className={`${isElection ? style.commentTextAreaElection : style.commentTextArea}`}
        onChange={handleTextareaChange}
        placeholder='Leave a comment'
        value={message}
      />

      <button
        className={`${isElection ? style.commentButtonElection : style.commentButton}`}
        disabled={submitButtonDisabled || !message}
        onClick={submit}
      >
        <svg fill='none' height='32' viewBox='0 0 32 32' width='32' xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M16 23V9M16 9L10 15M16 9L22 15'
            stroke={`${isElection ? '#000' : '#FFF'}`}
            strokeLinecap='round'
            strokeWidth='2'
          />
        </svg>
      </button>
    </div>
  );
};
