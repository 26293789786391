// DOM helpers

export function isScrolledToTheBottom(element?: HTMLElement, threshold?: number) {
  if (!element) return false;
  return element.scrollTop + element.offsetHeight >= element.scrollHeight - (threshold ?? 0);
}

// Other helpers

function getRandomBaseNumber() {
  if (typeof crypto !== 'undefined') {
    return crypto.getRandomValues(new Uint8Array(1)).toString();
  }

  const min = 0;
  const max = 255;
  return Math.random() * (max - min) + min;
}

export function makeNonceGenerator() {
  const base = getRandomBaseNumber();
  let count = 0;

  return () => {
    count += 1;
    return `${base}-c${count}`;
  };
}
