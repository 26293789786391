/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import React, { FC } from 'react';

import { Icon } from '@/components/design-system';

import style from './customStyle.module.css';
import { MessageForm, MessageFormProps } from './MessageForm';

export interface ReplyFormProps extends MessageFormProps {
  showOriginalMessage?: boolean;
  originalMessageContent?: string;
  showCloseButton?: boolean;
  onCloseClick?: () => void;
  isElection?: boolean;
}

export const ReplyForm: FC<ReplyFormProps> = ({
  showOriginalMessage,
  originalMessageContent,
  showCloseButton,
  onCloseClick,
  isElection,
  ...messageFormProps
}) => {
  return (
    <div className={style.replyFormRoot} data-element-name='reply-form'>
      <div className={`${isElection ? style.replyFormHeaderElection : style.replyFormHeader}`}>
        <div className={`${isElection ? style.replyFormIconElection : style.replyFormIcon}`}>
          <Icon name='REPLY' size={14} />
        </div>

        {showOriginalMessage && (
          <>
            <div className={`${isElection ? style.replyFormReSuffixElection : style.replyFormReSuffix}`}>Re:</div>
            <div className={`${isElection ? style.replyFormOriginalMessageElection : style.replyFormOriginalMessage}`}>
              {originalMessageContent}
            </div>
          </>
        )}

        {showCloseButton && (
          <button
            className={`${isElection ? style.replyFormCloseButtonElection : style.replyFormCloseButton}`}
            onClick={onCloseClick}
          >
            <Icon name='CLOSE' size={18} />
          </button>
        )}
      </div>

      <MessageForm {...messageFormProps} isElection={isElection} />
    </div>
  );
};
