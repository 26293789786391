import React, { FC } from 'react';

import styled from '@emotion/styled';

interface ScrollToBottomButtonProps {
  onClick?: () => void;
}

export const ScrollToBottomButton: FC<ScrollToBottomButtonProps> = ({ onClick }) => {
  return (
    <ButtonFloater>
      <Button onClick={onClick}>
        <Text>Scroll to latest</Text>
      </Button>
    </ButtonFloater>
  );
};

const ButtonFloater = styled.div`
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  pointer-events: none;
`;

const Button = styled.button`
  display: flex;
  margin: 0 auto;
  padding: 0 16px;
  border: none;
  border-radius: 100px;
  background: #ffffff;
  color: #1f1f1f;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.25);
  pointer-events: auto;
  font-family: 'Libre Franklin';
  font-size: 14px;
  font-weight: 600;
  line-height: 150%; /* 21px */
  letter-spacing: 0.28px;
`;

const Text = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 6px 6px 0;
`;
