import * as React from 'react';

import format from 'date-fns/format';
import NextLink from 'next/link';

import { Heading, Text, Icon } from '@/components/design-system';

interface EpisodeTitleAndBylineProps {
  title?: string | null;
  date: string;
  scheduleAt: string;
  showName: string;
  showSlug?: string | null;
  isUpcomingEpisode: boolean;
  isPragerU: boolean;
  textColor?: string;
  isElection?: boolean;
}

const EpisodeTitleAndByline: React.FC<EpisodeTitleAndBylineProps> = ({
  title,
  date,
  scheduleAt,
  showName,
  showSlug,
  isUpcomingEpisode,
  isPragerU,
  textColor,
  isElection = false,
}) => {
  return (
    <>
      {title && (
        <Heading
          css={(theme) => ({
            color: textColor || theme.colors.white,
            lineHeight: '130%',
            fontSize: '24px',
            fontWeight: isElection ? 500 : 400,
            [theme.mq.tablet]: {
              fontSize: '24px',
            },
          })}
        >
          {title}
        </Heading>
      )}
      {/* Byline */}
      {isPragerU && (
        <Text css={(theme) => ({ color: theme.colors.gray20 })} variant='100'>
          PragerU{' '}
          <span
            css={(theme) => ({
              color: theme.colors.gray20,
              margin: '0 .5rem',
              fontSize: 8,
            })}
          >
            &bull;
          </span>
        </Text>
      )}

      <>
        {showSlug && (
          <NextLink href={`/show/${showSlug}`} passHref>
            <Text css={() => ({ color: '#8F8F8F' })} variant='100'>
              {showName}
            </Text>
          </NextLink>
        )}
        <span
          css={() => ({
            color: '#8F8F8F',
            margin: '0 .5rem',
            fontSize: 8,
          })}
        >
          &bull;
        </span>
        <Text
          css={() => ({
            color: '#8F8F8F',
          })}
          variant='100'
        >
          {/* Clock Icon if upcoming */}
          {isUpcomingEpisode && <Icon css={{ color: '#2F78D2', marginRight: '8px' }} name='CLOCK' size={16} />}
          {/* Date */}
          {format(new Date(date), 'MMM d, yyyy')}
          {/* Start time */}
          {isUpcomingEpisode && ` at ${format(new Date(scheduleAt), 'h:mma')}`}
        </Text>
      </>
    </>
  );
};

export default EpisodeTitleAndByline;
