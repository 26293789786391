// Replies data transformation helpers

import { ChatMessage } from '../types';

function sortMessages(messages?: ChatMessage[]) {
  if (messages?.length < 2) return messages;
  return messages.sort((a, b) => a.timestamp - b.timestamp);
}

function deduplicateMessages(messages: ChatMessage[]) {
  const uniqueIds = new Set();
  return messages.reduce((acc, message) => {
    if (message.id && !uniqueIds.has(message.id)) {
      uniqueIds.add(message.id);
      acc.push(message);
    }

    return acc;
  }, [] as ChatMessage[]);
}

// Note: Partitions the combined array into separate messages and replies arrays
function partitionMessages(allMessages: ChatMessage[]) {
  if (!allMessages) return undefined;

  const messages: ChatMessage[] = [];
  const replies: ChatMessage[] = [];

  allMessages.forEach((item) => {
    if (item.replay_id) {
      return replies.push(item);
    }

    messages.push(item);
  });

  return [messages, replies];
}

// Note: This helper function normalizes inconsistent data and makes it consistent.
// By consistent we mean:
// - An array of messages is returned
// - All replies are placed under the `replies` property of the respective message
//   in that array
// - Stray replies (i.e., those that have a reply_id of a value of a message
//   we don't have in the array) are dismissed.
// - All messages and replies are unique (i.e., there are no unexpected duplicates)
//
// Expected input:
// - Messages and replies as siblings in the same array (this is what's coming from the server)
// - Messages _with_ replies on them (in `replies` properties)
// - Any combination of the above
export function normalizeMessageData(rawMessages: ChatMessage[]): ChatMessage[] {
  const [messages, newReplies] = partitionMessages(deduplicateMessages(rawMessages));

  const newRepliesByMessageIdMap: Map<number, ChatMessage[]> = newReplies.reduce(
    (map: Map<number, ChatMessage[]>, reply: any) => {
      const key = reply.replay_id;
      const repliesForId = map.get(key) ?? [];
      repliesForId.push(reply);
      map.set(key, repliesForId);

      return map;
    },
    new Map(),
  );

  return messages.map((message) => {
    const newReplies = newRepliesByMessageIdMap.get(message.id);
    // No need to do anything if there are no new replies,
    // no matter whether the message already had some replies or not
    if (!newReplies) return message;

    const replies = sortMessages(
      message.replies?.length > 0 ? deduplicateMessages([...message.replies, ...newReplies]) : newReplies,
    );

    return { ...message, replies };
  });
}

export function filterOutOptimisticMessages(messages?: ChatMessage[]) {
  return messages
    ?.filter((message) => !message.optimisticId)
    .map((message) => {
      if (!message.replies) return message;

      return {
        ...message,
        replies: message.replies?.filter((reply) => !reply.optimisticId),
      };
    });
}

export function resolveTailMessageId(messages?: ChatMessage[]) {
  if (!messages || messages.length < 1) return undefined;

  const sortedMessages = messages.sort((a, b) => b.id - a.id);
  return sortedMessages[0].id;
}

export function resolveTopMessageId(messages?: ChatMessage[]) {
  if (!messages || messages.length < 1) return undefined;

  const sortedMessages = messages.sort((a, b) => a.id - b.id);
  return sortedMessages[0].id;
}

// Note: This is a temporary hack. Apparently, we can't tell whether the user
// is an admin or not by just looking at their username.
const adminUsernames = new Set(['DW Addy', 'DW Intern']);

export function isUserAdmin(username?: string) {
  if (!username) return false;
  return adminUsernames.has(username);
}
