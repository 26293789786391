/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import React, { memo, useRef, useState } from 'react';
import style from './customStyle.module.css';
import { Message } from './Message';
import { MessageForm } from './MessageForm';
import { ChatMessage } from './types';
import { fakeMessages } from './fakeChats';

export type LiveChatFakeProps = {
  canLiveChat: boolean;
  roomId: string;
  roomStatus: string;
  updateRoomStatus?: (status: string) => void;
  isElection?: boolean;
};

export const LiveChatFake: React.FC<LiveChatFakeProps> = ({ canLiveChat = true, isElection, roomStatus }) => {
  const [messages, setMessages] = useState<ChatMessage[]>([...fakeMessages]);
  const [repliesShownMessageId, setRepliesShownMessageId] = useState<number>(undefined);

  const scrollableContainerElRef = useRef<HTMLDivElement>();

  const isRoomOpen = roomStatus === 'open';

  React.useEffect(() => {
    const interval = setInterval(() => {
      setMessages((prevMessages) => {
        // Shuffle the existing array
        const shuffledMessages = [...prevMessages].sort(() => Math.random() - 0.5);
        return shuffledMessages;
      });
    }, 2000);

    return () => clearInterval(interval);
  }, [setMessages]);

  return (
    <section
      className={`${isElection ? style.liveChatSectionElection : style.liveChatSection}`}
      style={{
        filter: canLiveChat ? 'none' : 'blur(4px)',
        WebkitFilter: canLiveChat ? 'none' : 'blur(4px)',
      }}
    >
      <div className={style.liveChatMainWrapper}>
        <div className={style.liveChatMain} ref={scrollableContainerElRef}>
          <div className={style.liveChatMessages}>
            {messages?.map((message) => {
              return (
                <Message
                  isElection={isElection}
                  key={message.id * Math.random()}
                  message={message}
                  newRepliesAllowed={isRoomOpen}
                  showReplies={Boolean(repliesShownMessageId && repliesShownMessageId === message.id)}
                />
              );
            })}
          </div>
        </div>
      </div>

      {isRoomOpen && (
        <div>
          <MessageForm isElection={isElection} />
        </div>
      )}
    </section>
  );
};
