/* eslint-disable import/no-unresolved */
import React, { FC } from 'react';

import { LiveChat, LiveChatProps } from '@/components/live-chat-new';
import { LiveChatFake } from '@/components/live-chat-new/LiveChatFake';

const EpisodeLiveChat: FC<LiveChatProps> = ({ canLiveChat, roomId, roomStatus, updateRoomStatus, isElection }) =>
  canLiveChat ? (
    <LiveChat
      canLiveChat={canLiveChat}
      isElection={isElection}
      roomId={roomId}
      roomStatus={roomStatus}
      updateRoomStatus={updateRoomStatus}
    />
  ) : (
    <LiveChatFake
      canLiveChat={canLiveChat}
      isElection={isElection}
      roomId={roomId}
      roomStatus={roomStatus}
      updateRoomStatus={updateRoomStatus}
    />
  );

export default EpisodeLiveChat;
