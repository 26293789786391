import React, { FC } from 'react';

import style from './customStyle.module.css';

export interface ReplyToggleProps {
  active?: boolean;
  replyCount?: number;
  onToggle?: (active?: boolean) => void;
}

export const ReplyToggle: FC<ReplyToggleProps> = ({ active, replyCount, onToggle }) => {
  // Note: In this case, the form itself will have a "close" button,
  // so we can safely hide the toggle
  if (active && !replyCount) return null;

  // Still doesn't have any replies but not active either
  if (!replyCount) {
    return (
      <div style={{ paddingLeft: 36 }}>
        <button className={style.openReplyListButton} onClick={() => onToggle?.(true)}>
          Reply
        </button>
      </div>
    );
  }

  // From now on there are only cases where we have replies

  if (active) {
    return (
      <div style={{ paddingLeft: 36 }}>
        <button className={style.openReplyListButton} onClick={() => onToggle?.(false)}>
          <strong>Hide replies</strong>
        </button>
      </div>
    );
  }

  return (
    <div style={{ paddingLeft: 36 }}>
      <button className={style.openReplyListButton} onClick={() => onToggle?.(true)}>
        <strong>
          View {replyCount > 1 ? 'replies' : 'reply'} ({replyCount})
        </strong>
      </button>
    </div>
  );
};
