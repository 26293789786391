import React, { FC } from 'react';

import Image from 'next/image';

import style from './customStyle.module.css';

const FALLBACK_IMAGE_URL = 'https://daily-wire-production.imgix.net/badges/cl0k2ptwbebcu0a70mgiu8ayi-1646932377320.png';

export interface MessageAvatarProps {
  imageUrl?: string;
}

export const MessageAvatar: FC<MessageAvatarProps> = ({ imageUrl }) => {
  return (
    <div className={style.messageAvatar}>
      <Image alt='Badge' height={32} src={imageUrl ?? FALLBACK_IMAGE_URL} width={32} />
    </div>
  );
};
